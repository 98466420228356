/**
 * Post Template
 * @Note - This template is only used to store data. A separate template is used
 *         for presentation. This is so that we can allow for real-time previews
 *         in a separate environment for content editors.
 */

import * as React from 'react';
import { graphql } from 'gatsby';

// Require the presentation component here, this is shared between the SSR template
// and the runtime preview template
import Page from '../presentation/post';

const PostTemplate = ({ data, pageContext, path }) => {
  return <Page data={data.wpPost} slug={pageContext.slug} path={path} />;
};

export default PostTemplate;

export const query = graphql`
  query($id: String) {
    wpPost(id: { eq: $id }) {
      id
      content
      title
      slug
      date(formatString: "Do MMMM YYYY")
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
      }
      articleAcf {
        subtitle
        featuredImage {
          sourceUrl
        }
        hasAuthor
        author {
          ... on WpStaffMember {
            id
            slug
            title
            staffAcf {
              profileImage {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;
